<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Hotel</h4>
                  <h1>Reservas Abandonadas</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <span class="p-input-icon-left" style="width: 100%;" c>
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar..." class="search" />
                  </span>
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <MultiSelect class="input table-columns-selector" :modelValue="selectedColumns" :options="columns" @update:modelValue="onToggle" placeholder="Seleccionar Columnas" />
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <ion-buttons class="options-nav">
                    <ion-button @click="exportCSV($event)">
                      <ion-icon color="dark" slot="icon-only" :icon="cloudDownloadOutline"></ion-icon>
                    </ion-button>
                    <ion-button @click="confirmMoveActive($event)" v-if="selectedElements.length && evolbeUser.business[0].rol !== 'analyst'">
                      <ion-icon color="success" slot="icon-only" :icon="checkmarkCircleOutline"></ion-icon>
                    </ion-button>
                    <ion-button @click="confirmCancelAllProducts($event)" v-if="selectedElements.length && evolbeUser.business[0].rol !== 'analyst'">
                      <ion-icon color="danger" slot="icon-only" :icon="closeCircleOutline"></ion-icon>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                  </ion-buttons>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>

        <section id="lists" class="mb-lg fullwidth-mobile">
            <DataTable 
                :value="elements"
                :totalRecords="totalRecords"
                ref="dt"
                :lazy="false"
                v-model:selection="selectedElements"
                dataKey="objectID"
                removableSort
                responsiveLayout="scroll"
                :paginator="true"
                :rows="25"
                v-model:filters="filters"
                :loading="loading"
                :reorderableColumns="true"
                :globalFilterFields="['name', 'first', 'second', 'desserts', 'drinks', 'uid']"
                filterDisplay="menu"
                :rowsPerPageOptions="[25,50,100]"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords} elementos"
              >
                <template #empty>
                    No hay resultados
                </template>
                <template #loading>
                    Cargando datos...
                </template>
                <Column selectionMode="multiple"></Column>

                <!-- Secuecia Normal -->
                <Column v-if="selectedColumns.includes('Nombre')" field="customer.firstName" header="Nombre" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field  table-primary-field">{{ slotProps.data.customer.firstName.toLowerCase() }}, {{ slotProps.data.customer.lastName.toLowerCase() }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Habitación')" field="room" header="Habitación" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.room }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Habitación"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Noches')" field="nights" header="Noches" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ slotProps.data.nights }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Total')" field="total" header="Total" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ Number(slotProps.data.total).toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Estado')" field="status" header="Estado" :sortable="true">
                  <template #body="slotProps">
                      <ion-chip v-if="slotProps.data.status === 0" color="dark"><ion-label >Abandonado</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 1" color="secondary" @click="nextStatus(slotProps.data)"><ion-label >Nuevo</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 2" color="primary" @click="nextStatus(slotProps.data)"><ion-label >Confirmado</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 3" color="warning" @click="nextStatus(slotProps.data)"><ion-label >Estancia</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 4" color="success"><ion-label >Completado</ion-label></ion-chip>
                      <ion-chip v-if="slotProps.data.status === 5" color="danger"><ion-label >Cancelado</ion-label></ion-chip>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

                <!-- Datos del Pedido -->
                <Column v-if="selectedColumns.includes('ID')" field="objectID" header="ID" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.objectID" class="table-field">#{{ slotProps.data.objectID }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha')" field="dateCreated" header="Fecha" dataType="date" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field table-field-date">{{ fechaDia(slotProps.data.dateCreated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha Entrada')" field="arrivalDate" header="Fecha Entrada" dataType="date" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fechaDia(slotProps.data.arrivalDate) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha Salida')" field="departureDate" header="Fecha Salida" dataType="date" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fechaDia(slotProps.data.departureDate) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Adultos')" field="adults" header="Adultos" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ slotProps.data.adults }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Niños')" field="childrens" header="Niños" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ slotProps.data.childrens }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" />
                  </template>
                </Column>

                <!-- Datos del Cliente -->
                <Column v-if="selectedColumns.includes('Email')" field="customer.email" header="Email" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field">{{ slotProps.data.customer.email }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Teléfono')" field="customer.phone" header="Teléfono" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field">{{ slotProps.data.customer.phone }}</span>
                  </template>
                </Column>

                <!-- Datos de los Totales -->
                <Column v-if="selectedColumns.includes('Subtotal')" field="subtotal" header="Subtotal" dataType="numeric" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.objectID" class="table-field">{{ slotProps.data.subtotal.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('I.V.A.')" field="tax" header="I.V.A." dataType="numeric" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.tax" class="table-field">{{ slotProps.data.tax.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <!-- Otros Datos -->
                <Column v-if="selectedColumns.includes('Tipo Pago')" field="payment.method" header="Tipo Pago" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.payment && slotProps.data.payment.method === 'stripe'" class="table-field">Stripe</span>
                      <span v-if="slotProps.data.payment && slotProps.data.payment.method === 'cash'" class="table-field">Efectivo</span>
                      <span v-if="slotProps.data.payment && slotProps.data.payment.method === 'dataphone'" class="table-field">Datáfono</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="paymentMethods" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Estado Pago')" field="payment.status" header="Estado Pago" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.payment && slotProps.data.payment.status === 'succeeded'" class="table-field">Exitoso</span>
                      <span v-if="slotProps.data.payment && slotProps.data.payment.status === 'pending'" class="table-field">Pendiente</span>
                      <span v-if="slotProps.data.payment && slotProps.data.payment.status === 'error'" class="table-field">Erroneo</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="paymentStatuses" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Idioma')" field="language" header="Idioma">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.language" class="table-field">{{ slotProps.data.language }}</span>
                  </template>
                </Column>

                <!-- Acciones -->
                <Column field="actions" style="width: 122px;">
                  <template #body="slotProps">
                      <ion-button shape="round" fill="outline" color="dark" @click="openDetails({component: 'hotel-booking', data: slotProps.data})">
                        <ion-label>Detalles</ion-label>
                      </ion-button>
                  </template>
                </Column>
            </DataTable>               
        </section>
       </div>

      <Footer></Footer>
  </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonChip, IonButton, IonButtons, IonLabel } from '@ionic/vue';
import { trashOutline, cloudDownloadOutline, addCircleOutline, archiveOutline,optionsOutline, ellipsisVerticalOutline, eyeOutline, checkmarkCircleOutline, createOutline, printOutline, arrowUpOutline, closeCircleOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline } from 'ionicons/icons';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ConfirmPopup from 'primevue/confirmpopup';

import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import Header from '@/components/Header.vue';

export default defineComponent({
  name: 'Products',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonChip,
    IonCol,
    IonIcon,
    Footer,
    DataTable,
    Column,
    InputText,
    IonButtons, 
    IonLabel,
    IonButton,
    ConfirmPopup,
    Calendar,
    InputNumber,
    Dropdown,
    Header,
    MultiSelect
  },
    data() {
      return {
        search: '',
        selectedElements: [],
        filters: null,
        openFilters: false,
        loading: true,
        lazyParams: null,
        totalRecords: 0,
        selectedColumns: null,
        columns: [
          'ID',
          'Fecha Entrada',
          'Fecha Salida',
          'Noches',
          'Habitación',
          'Adultos',
          'Niños',

          'Nombre',
          'Email',
          'Teléfono',

          'Subtotal',
          'Total',
          'I.V.A.',

          'Tipo Pago',
          'Estado Pago',
          'Idioma',
          'Estado',
          'Fecha ',
        ],

        statuses: [
          {
            label: 'Abandodano',
            color: 'dark',
            value: 0,
            command: () => {
              this.changeStatus(0);
            }
          },
          {
            label: 'Nuevo',
            color: 'secondary',
            value: 1,
            command: () => {
              this.changeStatus(1);
            }
          },
          {
            label: 'Confirmado',
            color: 'primary',
            value: 2,
            command: () => {
              this.changeStatus(2);
            }
          },
          {
            label: 'Estancia',
            color: 'warning',
            value: 3,
            command: () => {
              this.changeStatus(3);
            }
          },
          {
            label: 'Completado',
            color: 'success',
            value: 4,
            command: () => {
              this.changeStatus(4);
            }
          },
          {
            label: 'Cancelado',
            color: 'danger',
            value: 5,
            command: () => {
              this.changeStatus(5);
            }
          },          
        ],

        paymentMethods: [
          {
            label: 'Stripe',
            value: 'stripe',
          },
          {
            label: 'Efectivo',
            value: 'cash',
          },
          {
            label: 'Datáfono',
            value: 'dataphone',
          },
        ],
        paymentStatuses: [
          {
            label: 'Exitoso',
            color: 'success',
            value: 'succeeded',
          },
          {
            label: 'Pendiente',
            color: 'warning',
            value: 'pending',
          },
          {
            label: 'Error',
            color: 'danger',
            value: 'error',
          },
        ],

      };
    },
    computed: {
      ...mapState('hotel', {
        elements: state => state.abandonedBookings,
      }),

      ...mapState(['evolbeUser', 'preferences'])
    },
    methods: {
      ...mapActions('hotel',['getAbandonedBookings', 'deleteAbandonedBooking', 'updateAbandonedBooking', 'addBooking']),
      ...mapActions(['openDetails']),

      // Initialization
      initFilters() {
          this.selectedColumns = [
            'Nombre',
            'Habitación',
            'Noches',
            'Total',
            'Estado'
          ];

          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
              'dateCreated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'total': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'room': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
              'subtotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'tax': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'payment.method': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'payment.status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
          }

          this.lazyParams = {
              first: 0,
              rows: 0,
              sortField: null,
              sortOrder: null,
              filters: this.filters
          }
      },

      // Table
      onPage(event) {
        this.lazyParams = event;
        this.loadLazyData();
      },

      onToggle(value) {
        this.selectedColumns = this.columns.filter(col => value.includes(col));
      },

      onSort(event) {
        this.lazyParams = event;
        this.loadLazyData();
      },

      exportCSV() {
          this.$refs.dt.exportCSV();
      },


      // Masive Functions
      confirmMoveActive(event){
        this.$confirm.require({
            target: event.currentTarget,
            message: '¿Seguro de que quieres mover a activos estos elementos?',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //callback to execute when evolbeUser confirms the action
                this.selectedElements.forEach(order => {
                  const newData = order;
                  newData.status = 1;


                  if(!newData.payment){
                    newData.payment = {
                      'method': 'stripe',
                      'status': 'successful'
                    }
                  }

                  this.addBooking({data: newData, id: newData.objectID} );
                  this.deleteAbandonedBooking(newData.objectID);
                });

                this.selectedElements = [];
            },
            reject: () => {
                //callback to execute when evolbeUser rejects the action
            }
        });
      },

      confirmCancelAllProducts(event){
        this.$confirm.require({
            target: event.currentTarget,
            message: '¿Seguro de que quieres cancelar estos elementos?',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //callback to execute when evolbeUser confirms the action
                this.selectedElements.forEach(order => {
                  const newData = order;
                  newData.status = 5;
                  this.updateAbandonedBooking({data: newData, id: newData.objectID} );
                });

                this.selectedElements = [];
            },
            reject: () => {
                //callback to execute when evolbeUser rejects the action
            }
        });
      },

      // Others
      scrollToTop(){
        const scrollContent = document.querySelector('ion-content.bindingScrollContent');
        scrollContent.scrollToTop(1000)
      },

      fecha(date){
        return moment(date).format('DD/MM/YYYY-HH:mm');
      },

      fechaHora(date){
        return moment(date).format('HH:mm');
      },

      fechaDia(date){
        return moment(date).format('DD/MM/YYYY');
      },

      async loadLazyData() {
        this.loading = true;

        await this.getAbandonedBookings(this.lazyEvent).then(res => {
          this.loading = false;
          if(res){
            this.totalRecords = res.length; 
          }
        })
      },
    },
    created(){
      this.initFilters();
      this.loadLazyData();
      moment.locale('es');
    },
    setup() {
      return { trashOutline, cloudDownloadOutline, eyeOutline, archiveOutline, ellipsisVerticalOutline, checkmarkCircleOutline, addCircleOutline, optionsOutline, createOutline, printOutline, closeCircleOutline, arrowUpOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline };
    }
});
</script>

<style scoped>
  .table-field{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .table-primary-field{
    font-weight: bold;
  }

</style>